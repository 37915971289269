import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroImgR from "../components/Hero/HeroImgR"
import HeroImgL from "../components/Hero/HeroImgL"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HeroImgR
      buttonText="Ready for antMan CyberSec? Take the quiz now. "
      buttonLink="https://antman.paperform.co/"
      image="https://i.imgur.com/sz1dhJb.png"
    >
      <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-yellow-400 to-red-500 dark:from-green-200 dark:via-yellow-200 dark:to-red-400">Simple. Private. Cloud. 🚀</h1>
      <p className="text-gray-800 dark:text-gray-100">Spend time executing on your mission, not managing and configuring your infrastructure. Speed up the workflow for your cybersec lab, devops environment, or edge production.</p>
    </HeroImgR>
    <HeroImgL
      buttonText="Ready for antMan CyberSec? Take the quiz now. "
      buttonLink="https://antman.paperform.co/"
      image="https://i.imgur.com/sz1dhJb.png"
    >
      <h1 className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-yellow-400 to-red-500 dark:from-green-200 dark:via-yellow-200 dark:to-red-400">Simple. Private. Cloud. 🚀</h1>
      <p className="text-gray-800 dark:text-gray-100">Spend time executing on your mission, not managing and configuring your infrastructure. Speed up the workflow for your cybersec lab, devops environment, or edge production.</p>
    </HeroImgL>
  </Layout>
)

export default IndexPage
