import React from "react"
import PropTypes from "prop-types"
import "./hero.scss"

export default function HeroImgR({
  image,
  alt,
  children,
  buttonText,
  buttonLink,
}) {
  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__container--left">
          {children}
          <div id="btn-container">
            <button className="hero__container--btn inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
              <a href={buttonLink}>{buttonText}</a>
            </button>
          </div>
        </div>
        <div className="hero__container--right">
          <img
            className="hero__container--img"
            src={image}
            placeholder="blurred"
            alt={alt}
          />
        </div>
      </div>
    </div>
  )
}

HeroImgR.propsTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.any.isRequired,
  alt: PropTypes.string,
}
